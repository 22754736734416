$carouselBorderColor: #ddd;
.swiper-container {
  width: 100%;
  height: 470px;
  margin: 0 auto 100px auto;
  border-top: solid 1px $carouselBorderColor;
  border-bottom: solid 1px $carouselBorderColor;
}
.swiper-slide {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  > a {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.l-section > .l-section-item > .c-carousel > .swiper-container {
  margin-top: 60px;
  margin-bottom: 0;
  border: none;
}

/**
 * for PC, Tablet
 */
@media (min-width: 768px) {
  .c-carousel-sitetop > .swiper-container {
    height: 720px;
  }
  .c-carousel-gallery > .swiper-container {
    height: 650px;
  }
}

/**
 * for Smartphone
 */
@media (max-width: 767px) {
  .swiper-button-next, .swiper-button-prev {
    width: 16px;
    background-size: 16px auto;
  }
  .c-carousel-sitetop > .swiper-container {
    height: 216px;
    margin: 0 0 16px 0;
  }

  .c-carousel-gallery > .swiper-container {
    height: 300px;
  }


  .l-section > .l-section-item > .c-carousel > .swiper-container {
    margin-top: 24px;
  }
}
